
/* iframe full size */
.fullsize-iframe {
    width: 100%;
    height: calc(100vh - 123px);
}

/* header menu - desktop only */
.header-menu-desktop {
    font-size: 13px;
}

.header-vertical-divider {
    border-right-color: lightgray;
    border-right-style: solid;
    border-right-width: 1px;
    border-top: 10px solid transparent;         /* divider is 17px. Couldn't work out how to increase length. Added border top to simulate more length */
    border-bottom: 20px solid transparent;      /* divider is 17px. Couldn't work out how to increase length. Added border bottom to simulate more length */
}

.topmenu-text-top {
    vertical-align: text-top;
}

.recruitment-icon path {
    fill: gray;
}


/* side bar nav - header icon */
.nav-header-icon {
    height: 45px;
}

/* core ui - sidebar navigation */
.sidebar-nav .nav-title {
    font-size: 18px;
    color: #87aff0;;
}

.simplebar-content > .nav-group > .nav-link.nav-group-toggle {
    font-size: 15px;
    color: #193981;
    font-weight: bold;
    text-transform: uppercase;
}

.sidebar-nav .nav-link.active {
    /* color: black; */
    font-weight: bold;
    /* background-color: inherit;   */
}

/* start sidebar and header nav underline on hover and active */
.nav-group a {
    border-bottom: 5px solid transparent;
}

.nav-group a:hover {
    border-bottom: 5px solid #fa5934;
}

.nav-group a.active {
    border-bottom: 5px solid #fa5934;
}

.header-nav .item {
    border-top: 11px solid transparent;     /* could not work out how to vertical align middle the header, therefore added 11px boarder to simulate middle alignment */
    border-bottom: 5px solid transparent;
    padding-left: 5px;
    padding-right: 5px;
    font-size: 14px;
    color: #193981;
    height: 44px;          /* increase height to have greater bg colour. But text becomes top aligned */
}

.header-nav .item:hover {
    border-bottom: 5px solid #fa5934;
    background-color: rgba(250, 89, 52, .1);
}

.header-nav .item.active {
    border-bottom: 5px solid #fa5934;
}
/* end sidebar and header nav underline on hover and active */

.sidebar-open-url {
    color: #4f5d73;
}

.sidebar-open-url:hover {
    color: #000015;
}

/* 3rd step menu - set left margin for easy distinction */
.sidebar-nav .nav-group-items .nav-group > .nav-group-items{
    padding-left: 10px;
    background-color:rgb(239, 250, 253);
}  

/* 3rd step menu - change background color */
/* .sidebar-nav .nav-group-items .nav-group.show {
    background-color:lightyellow;
}  */

/* only desktop mode, set white background color for sidebar menu */
/* @media only screen and (min-width: 768px) {

    .sidebar-nav .nav-group.show {
        background-color: white;
    }
}   */


/* semantic ui - modal style change */
.ui.modal {
    height: auto;
    top: auto;
    left: auto;
    bottom: auto;
    right: auto;
    margin: auto;
    position: relative !important;
}

.ui.dimmer { 
    background-color: rgba(0, 0, 0, 0.20) !important 
}


/* material ui - data grid header */
.MuiDataGrid-main .MuiDataGrid-columnHeaderTitle { 
    font-weight: bold !important; 
}

.MuiDataGrid-main .MuiDataGrid-columnHeaders { 
    background-color: #f5f5f5 !important;
}

/* material ui - autocomplete */
.MuiOutlinedInput-root.MuiInputBase-sizeSmall .MuiAutocomplete-input {
    padding: 2.5px 6px 6px 6px !important;  
}

/* MUI Accordion Header style */
.accordion-panel-header {
    /* background-color: #859AAC; */
    background-color: #193981;
    color: white; 
    height: 50px; 
    min-height: 50px !important;
    padding-left: 20px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.accordion-panel-header > .MuiAccordionSummary-content {
    justify-content: space-between;
}

.MuiStepLabel-label,
.MuiStepLabel-alternativeLabel,
.MuiFormControlLabel-label {
    font-family: Lato !important;
}

.MuiStepLabel-alternativeLabel {
    font-size: 1rem;
}

.MuiSvgIcon-root {
    width: 1.2em;
    height: 1.2em;
}

.MuiSvgIcon-root.Mui-active {
    color: #ce218a
}

.currencyfield-layout .MuiInputBase-input{
    padding: 8.5px 14px !important;
}

.MuiInputBase-root input.MuiInputBase-input {
    padding: 7px 14px 10px 14px !important;
}

/* Mui Textfield Readonly style - Background color */
.readonly-textfield input.MuiInputBase-input {
    z-index: 1000;
}

.readonly-textfield .MuiOutlinedInput-notchedOutline {
    background-color: rgb(240, 240, 240);
}

.readonly-textarea {
    background-color: rgb(240, 240, 240);
}

.readonly-light-textfield .MuiOutlinedInput-notchedOutline {
    background-color: rgb(192, 192, 192);
    opacity: 0.4;
}


.tiny-size-textfield .MuiOutlinedInput-root.MuiInputBase-sizeSmall
{
    padding: 3px;
}

/* TextField to edit in Kendo Grid */
.grid-edit-textfield .MuiInputBase-root input.MuiInputBase-input {
    padding: 5px 0 !important; 
}

.MuiFormHelperText-root.Mui-error {
    font-family: Lato !important;
}

.file-upload-alert .MuiAlert-message {
    padding-top: 12px
}


/* Kendo Grid - content */
.k-grid-content.k-virtual-content{
    font-size: 13px; 
}

.small-fontsize-grid .k-grid-content.k-virtual-content{
    font-size: 11px; 
}


.k-grid{
    padding: 0;
}

.k-grid-footer {
    background-color: #eef3fc;
}

.k-grid tbody tr:hover {
    background-color: rgb(195, 219, 228);
}

.k-animation-container {
    z-index: 1303 !important;  
} 

.popup-date-picker .k-datepicker  .k-dateinput .k-input {
    height: 35px;
}

.small-height-datepicker .k-dateinput .k-input {
    padding-top: 0 ;
    padding-bottom: 0;
    height: unset;
}

.input-right-align .k-input {
    text-align: right;
}

/* Kendo Grid - No Header */
.hide-grid-header .k-grid-header { 
    display : none ; 
}

.overflow-y-auto .k-grid-header{
    padding-right: 0;
    overflow-x: hidden;
}

.overflow-y-auto .k-grid-content{
    overflow-y: auto;
}



/* Kendo Grid Header - Hide filter operator button (e.g. is greater than...) */
.k-filtercell-wrapper .k-filtercell-operator .k-dropdown-operator {
    display : none ; 
} 

/* Kendo Grid Header - Hide filter close button */
.k-filtercell .k-filtercell-operator > .k-button:not(.k-clear-button-visible) {
    display: none;
}

/* Kendo Grid Header - Modify filter box padding */
.k-filter-row > th {
    padding-right: 2px;
    padding-left: 4px;
}

.grid-error-border {
    border: 1px solid #d32f2f;
}

.house-units-details .k-detail-cell {
    padding: 0 12px;
}

.k-grid-edit-cell.checkbox-editor {
    text-align: center;
}

.k-master-row .grid-center-align {
    text-align: center;
}

.table-header-middle .k-header {
    vertical-align: middle;
}

/* Kendo Grid <td>, change row height to 30px */
.tight-row-height.k-grid td {
    padding: 6px 12px;
}

/* Mobile Access Alert Modal */
.mobile-access-modal {
    width: 90vw !important;
    margin-left: 0 !important;;
    bottom: 40vh !important;;
}

/* EditVersionSubDialog, row details  */
.row-details {
    padding: 2px 0;
    align-items: center;
}

/* semantic ui - modal top-right close button style*/
.ui.modal > .close.icon {
    top: 0;
    right: 0;
    color: grey;
}

.ui.modal > .actions{
    text-align: center;
}

.header-menu-item {
    margin: auto 7px;
}

.header-menu-item > .ui.dropdown > .dropdown.icon {
    margin: 0 0 0 3px;
}

/* PDF exporting */
.pdf-quote-comment-review {
    font-size: 12px;
    font-weight: bold;
    font-style: italic;
}

.pdf-quote-comment {
    font-size: 8px;
    font-weight: bold;
    font-style: italic;
}

/* Static bottom button panel */
.static-bottom-button {
    height: 50px;
    align-items: center;
    background-color: #d4e1fb;
    border:1px solid lightgray;
    position: fixed;
    width: 100%;
    left: 0;
    bottom: 0px;
    margin-right: 0;
    margin-left: 0;
    z-index: 11;
}

/* Google Place AutoComplete - placeholder color */
.google-places div[class$="-placeholder"] {
    color:hsl(0, 0%, 75%);
    margin-left: 5px;
}

/* Phone Input Component Style */
.phone-number-input {
   height: 42px;
   padding: 10px 12px 10px 12px;
   border: 1px solid lightgray;
   border-radius: 5px;
}

.phone-number-input::placeholder {
   color: hsl(0, 0%, 75%);
}

.phone-number-input:focus {
    border-color: #1976D2;
    outline-color: #1976D2;
}


/* Progress status CSS  start*/
.lead-progress {
	display: inline-block;
	text-align: center;
    justify-content: center;
	overflow:hidden;
}

.lead-progress .noSale{
  background-color: red;
  color: white;
  padding-bottom: 40px;
}

.lead-progress div{
    display: inline;
    float: left;
    height: 50px;
    background: grey;
    text-align: center;
    padding: 16px 0 38px 40px;
    position: relative;
    margin: 15px 5px 0 0;
    font-size: 16px;
    text-decoration: none;
    color: #fff;
}

.lead-progress .done{
    background: #9cd7fa;
    color: rgb(61, 61, 61);
}
.lead-progress div.done::after {
    border-left-color:#9cd7fa;
}

.lead-progress .active{
    background: #2489c5;
    color: #fff;
}
.lead-progress div.active::after {
    border-left-color:#2489c5;
}

.lead-progress div::after{
  content: "";
  border-top: 27px solid transparent;
  border-bottom: 27px solid transparent;
  border-left: 27px solid grey;
  position: absolute;
  right: -27px;
  top: 0;
  z-index: 1;
}

.lead-progress div::before{
    content: "";
    border-top: 27px solid transparent;
    border-bottom: 27px solid transparent;
    border-left: 27px solid white;
    position: absolute;
    left: 0;
    top: 0;
}

.lead-progress div:first-child:before,
.lead-progress div:last-child:after {
    border: none;
}

.lead-progress div:first-child {
    padding-left:30px;
    border-top-left-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
}

.lead-progress div:last-child {
    padding-right: 30px;
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
}

/* Sales Lead, File Upload Dialog - Dropzone Style */
.file-dropzone {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 20px;
    border-width: 2px;
    border-radius: 2px;
    border-color: #eeeeee;
    border-style: dashed;
    background-color: #fafafa;
    color: #0164a5;
    outline: none;
    transition: border .24s ease-in-out;
    font-size: 15px;
}

.file-dropzone:focus {
    border-color: #2196f3;
}

.file-dropzone.disabled {
    opacity: 0.6;
}

/* If text is too long, only display one line and ... at 'Sales Lead History(Action)' in 'Lead Detail Dialog' */
.only-oneline-text {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
  
/* LeadDetailDialog, Progress - Button for editing 'Next Follow Up' date */
.compact-icon-button {
    margin: -6px 0 0 -7px !important;
}

/* LeadDetailDialog, Progress Step Style */
.react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
    display: none;
  }
  
.react-horizontal-scrolling-menu--scroll-container {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}
  
.react-horizontal-scrolling-menu--arrow-left,
.react-horizontal-scrolling-menu--arrow-right {
   align-items: center;
}
  

/* this part chops the content of accordion into 3 columns at 'My Dashboard' in 'Leads and Pipeline' */
.Connect-Dashboard-Leads-Pipeline{
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    -webkit-column-gap: 3em;
    -moz-column-gap: 3em;
    column-gap: 0.5em;
}

/* 'Show More', 'Show Less' text style.. */
.show-more-less-clickable {
    color: blue;
    background-color: rgb(234, 234, 255);
}

/* Kento Time picker - Placeholder colour change */
.kendo-time-picker .k-input::placeholder {
    color: #b7b7b7;
 }

 .grid-tool-bar-autocomplete .k-grid-toolbar {
    background-color: lightyellow; 
 }

 /* home panel style - red background colour */
.home-panel-table-header .k-grid-table td{
    padding: 0px 10px;
}
  
.home-panel-table-header .k-grid-header .k-header {
    padding: 5px;
    background-color: #fa5934;
}
  
.home-panel-table-header .k-grid-header .k-i-sort-asc-sm,
.home-panel-table-header .k-grid-header .k-i-sort-desc-sm {
   color: white;
}
  